<template>
  <b-container
    fluid
    class="mb-5"
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Company</label>
            <b-select
              v-model="filter.company"
              :options="filterCompanies"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button
            size="sm"
            @click="onShowProcessForm(row.item)"
          >
            Process
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-pending-reservations-form"
      scrollable
      no-close-on-backdrop
      title="Process Reservation"
      @ok="onConfirmAction"
      @show="onShownProcessForm"
    >
      <b-card no-body>
        <ValidationObserver ref="form">
          <form
            novalidate
            role="form"
          >
            <b-tabs>
              <b-tab
                title="Reservation Details"
                active
              >
                <b-form-group>
                  <label for="created_at">Reserved At</label>
                  <b-input
                    id="created_at"
                    v-model="pendingReservation.created_at"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="user_name">Reserved By</label>
                  <b-input
                    id="user_name"
                    v-model="pendingReservation.user_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="company">Company</label>
                  <b-input
                    id="company"
                    v-model="pendingReservation.company_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="organization">Organization</label>
                  <b-input
                    id="organization"
                    v-model="pendingReservation.organization_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="reservation_type">Reservation Type</label>
                  <b-input
                    id="reservation_type"
                    v-model="pendingReservation.reservation_type"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="vehicle_type_name">Vehicle Type</label>
                  <b-input
                    id="vehicle_type_name"
                    v-model="pendingReservation.vehicle_type_name"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group v-if="pendingReservation.reservation_type === 'Service Vehicle'">
                  <label for="passenger_count">Passenger Count</label>
                  <b-input
                    id="passenger_count"
                    v-model="pendingReservation.passenger_count"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="departure_date">Departure</label>
                  <b-input
                    id="departure_date"
                    v-model="departureDateTime"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="arrival_date">Arrival</label>
                  <b-input
                    id="arrival_date"
                    v-model="arrivalDateTime"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="purpose">Purpose</label>
                  <b-textarea
                    id="purpose"
                    v-model="pendingReservation.purpose"
                    type="text"
                    rows="6"
                    max-rows="6"
                    :disabled="true"
                  />
                </b-form-group>
              </b-tab>
              <b-tab
                v-if="pendingReservation.attachments.length > 0"
                title="Attachments"
              >
                <b-table
                  class="mt-1"
                  :fields="attachmentTableSettings.fields"
                  :items="pendingReservation.attachments"
                  responsive
                  striped
                  hover
                >
                  <template #cell(actions)="row">
                    <div class="text-nowrap">
                      <b-button
                        size="sm"
                        class="mr-1"
                        @click="onPreviewAttachment(row.item)"
                      >
                        Preview
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-tab>

              <b-tab
                v-if="pendingReservation.reservation_type !== 'Service Driver'"
                title="Vehicle Details"
              >
                <b-form-group>
                  <label for="vehicle_name">Vehicle (Type any string to search)</label>
                  <v-select
                    id="vehicle_name"
                    v-model="selected.vehicle"
                    type="text"
                    :options="list.vehicles"
                    label="name"
                    :disabled="state.busy"
                  >
                    <template #option="{ name }">
                      <strong>{{ name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Vehicle
                    </template>
                  </v-select>
                </b-form-group>

                <b-form-group>
                  <label for="license_plate">License Plate</label>
                  <b-input
                    id="license_plate"
                    v-model="pendingReservation.vehicle_license_plate"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="registration_expiry">Registration Expiry</label>
                  <b-input
                    id="registration_expiry"
                    v-model="pendingReservation.vehicle_registration_expiry"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="seat_capacity">Seat Capacity</label>
                  <b-input
                    id="seat_capacity"
                    v-model="pendingReservation.vehicle_seat_capacity"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

              </b-tab>

              <b-tab
                v-if="pendingReservation.reservation_type !== 'Service Vehicle'"
                title="Driver Details"
              >
                <b-form-group>
                  <label for="driver_name">Driver (Type any string to search)</label>
                  <v-select
                    id="driver_name"
                    v-model="selected.driver"
                    type="text"
                    :options="list.drivers"
                    label="name"
                    :disabled="state.busy"
                  >
                    <template #option="{ name }">
                      <strong>{{ name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Driver
                    </template>
                  </v-select>
                </b-form-group>

                <b-form-group>
                  <label for="driver_license_type">License Type</label>
                  <b-input
                    id="driver_license_type"
                    v-model="pendingReservation.driver_license_type"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="driver_license">License Number</label>
                  <b-input
                    id="driver_license"
                    v-model="pendingReservation.driver_license_number"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

                <b-form-group>
                  <label for="driver_license_expiry">License Expiry</label>
                  <b-input
                    id="driver_license_expiry"
                    v-model="pendingReservation.driver_license_expiry"
                    type="text"
                    :disabled="true"
                  />
                </b-form-group>

              </b-tab>

              <b-tab title="Approval Details">
                <ValidationProvider
                  #default="{ errors }"
                  name="remarks"
                  vid="processed_remarks"
                  rules="required"
                >
                  <b-form-group>
                    <label for="processed_remarks">Remarks</label>
                    <b-textarea
                      id="processed_remarks"
                      v-model="pendingReservation.processed_remarks"
                      rows="6"
                      max-rows="6"
                      :state="errors.length > 0 ? false : null"
                      autocomplete="off"
                      placeholder="enter remarks"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                    >
                      <span>{{ errors[0] }}</span>
                    </div>
                  </b-form-group>
                </ValidationProvider>
              </b-tab>
            </b-tabs>
          </form>
        </ValidationObserver>
      </b-card>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          {{ isApproved ? 'Approve' : 'Disapprove' }}
        </b-button>

        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>

    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${file.name}]`"
      size="lg"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, LogisticsOfficerReservationService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'LogisticsOfficerPendingReservations',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Pending Reservations'
    }
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        editing: false,
        busy: false,
        file: {
          previewing: false
        }
      },
      list: {
        approvalStatuses: [
          { value: 'Approved', text: 'Approved' },
          { value: 'Disapproved', text: 'Disapproved' }
        ],
        companies: [],
        vehicles: [],
        drivers: []
      },
      filter: {
        company: 'All'
      },
      selected: {
        driver: null,
        vehicle: null
      },
      file: {
        preview: null,
        name: ''
      },
      pendingReservation: {
        id: 0,
        status: 'Disapproved',
        user: null,
        user_name: '',
        company_name: '',
        organization_name: '',
        reservation_type: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        vehicle_type: null,
        vehicle_type_name: '',
        passenger_count: 0,
        purpose: '',
        vehicle: null,
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_registration_expiry: '',
        vehicle_seat_capacity: 0,
        driver: null,
        driver_name: '',
        driver_license_type: '',
        driver_license_number: '',
        driver_license_expiry: '',
        created_at: '',
        processed_remarks: '',
        file_path: '',
        attachments: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'Reserved At', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'user_name', label: 'Reserved By' },
          { key: 'company_name' },
          'reservation_type',
          { key: 'vehicle_type_name', label: 'Vehicle Type' },
          'departure',
          'arrival'
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    filterLicenseType () {
      return [{ value: 'All', text: 'All' }].concat(this.list.licenseType)
    },

    filterCompanies () {
      return [{ text: 'All', value: 'All' }].concat(this.list.companies)
    },

    departureDateTime () {
      if (this.pendingReservation.departure_date !== '' && this.pendingReservation.departure_time !== '') {
        return this.dateTimeFormatterLong(`${this.pendingReservation.departure_date} ${this.pendingReservation.departure_time}`)
      }
      return ''
    },

    arrivalDateTime () {
      if (this.pendingReservation.arrival_date !== '' && this.pendingReservation.arrival_time !== '') {
        return this.dateTimeFormatterLong(`${this.pendingReservation.arrival_date} ${this.pendingReservation.arrival_time}`)
      }
      return ''
    },

    isApproved () {
      if (this.pendingReservation.reservation_type === 'Service Driver') {
        return this.selected.driver !== null
      }

      if (this.pendingReservation.reservation_type === 'Service Vehicle') {
        return this.selected.vehicle !== null
      }

      return this.selected.vehicle !== null && this.selected.driver !== null
    },

    confirmationMessage () {
      if (this.isApproved) {
        return 'Do you really want to approved reservation?'
      }
      return 'Do you really want to disapproved reservation?'
    }
  },

  watch: {
    'selected.driver' (value) {
      if (value) {
        this.pendingReservation.driver = value.id
        this.pendingReservation.driver_name = value.name
        this.pendingReservation.driver_license_type = value.license_type
        this.pendingReservation.driver_license_number = value.license_number
        this.pendingReservation.driver_license_expiry = value.license_expiry
      } else {
        this.pendingReservation.driver = null
        this.pendingReservation.driver_name = ''
        this.pendingReservation.driver_license_type = ''
        this.pendingReservation.driver_license_number = ''
        this.pendingReservation.driver_license_expiry = ''
      }
    },
    'selected.vehicle' (value) {
      if (value) {
        this.pendingReservation.vehicle = value.id
        this.pendingReservation.vehicle_name = value.name
        this.pendingReservation.vehicle_license_plate = value.license_plate
        this.pendingReservation.vehicle_registration_expiry = value.registration_expiry
        this.pendingReservation.vehicle_seat_capacity = value.seat_capacity
      } else {
        this.pendingReservation.vehicle = null
        this.pendingReservation.vehicle_name = ''
        this.pendingReservation.vehicle_license_plate = ''
        this.pendingReservation.vehicle_registration_expiry = ''
        this.pendingReservation.vehicle_seat_capacity = ''
      }
    },
    'isApproved' (value) {
      if (value) {
        this.pendingReservation.status = 'Approved'
      } else {
        this.pendingReservation.status = 'Disapproved'
      }
    }
  },

  mounted () {
    core.index()
    this.fetchCompanyList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await LogisticsOfficerReservationService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_company=${this.filter.company}&filter_text=${ctx.filter}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    async onShownProcessForm () {
      const filter = `vehicle_type=${this.pendingReservation.vehicle_type}&departure_date=${this.pendingReservation.departure_date}&departure_time=${this.pendingReservation.departure_time}&arrival_date=${this.pendingReservation.arrival_date}&arrival_time=${this.pendingReservation.arrival_time}`
      this.state.busy = true

      if (this.pendingReservation.reservation_type !== 'Service Vehicle') {
        await SharedListService.getAvailableDrivers(filter).then(({ data }) => {
          this.list.drivers = data
        })
      }

      if (this.pendingReservation.reservation_type !== 'Service Driver') {
        await SharedListService.getAvailableVehicles(filter).then(({ data }) => {
          this.list.vehicles = data
        })
      }

      this.state.busy = false
    },

    async fetchCompanyList () {
      await SharedListService.getCompanies().then(({ data }) => {
        this.list.companies = data.map(company => ({
          text: company.name,
          value: company.id
        }))
      })
    },

    onShowNewForm () {
      this.state.editing = false
      this.$bvModal.show('modal-pending-reservations-form')
    },

    async onShowProcessForm (item) {
      this.state.editing = true
      this.pendingReservation.id = item.id
      this.pendingReservation.user_name = item.user_name
      this.pendingReservation.company_name = item.company_name
      this.pendingReservation.organization_name = item.organization_name
      this.pendingReservation.reservation_type = item.reservation_type
      this.pendingReservation.departure_date = item.departure_date
      this.pendingReservation.departure_time = item.departure_time
      this.pendingReservation.arrival_date = item.arrival_date
      this.pendingReservation.arrival_time = item.arrival_time
      this.pendingReservation.vehicle_type = item.vehicle_type_id
      this.pendingReservation.vehicle_type_name = item.vehicle_type_name
      this.pendingReservation.passenger_count = item.passenger_count
      this.pendingReservation.purpose = item.purpose
      this.pendingReservation.attachments = item.attachments
      this.pendingReservation.created_at = this.dateTimeFormatter(item.created_at)
      this.pendingReservation.processed_remarks = ''
      this.pendingReservation.file_path = item.file_path
      this.selected.driver = null
      this.selected.vehicle = null
      this.$bvModal.show('modal-pending-reservations-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: this.confirmationMessage,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oppss! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        LogisticsOfficerReservationService.put({
          id: this.pendingReservation.id,
          status: this.pendingReservation.status,
          vehicle: this.pendingReservation.vehicle,
          driver: this.pendingReservation.driver,
          processed_remarks: this.pendingReservation.processed_remarks
        }).then(({ data }) => {
          this.$bvModal.hide('modal-pending-reservations-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = this.getImageUrl(`${this.pendingReservation.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
